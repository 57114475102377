<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="等级名称:">
                    <el-input class="s-input" v-model="queryParams.title" placeholder="请输入等级名称" clearable></el-input>
                </el-form-item>

                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" :data="pageInfo.list" :row-class-name="tableRowClassName">
                <el-table-column label="icon图片" prop="icon" align="center" width="350">
                    <template slot-scope="scope">
                        <!-- <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;border-radius:25px;margin-top: 10px" :class="`column${scope.$index}`" class="table-td-thumb" :src="scope.row.icon" :preview-src-list="[scope.row.icon]">
                            <div slot="error" class="image-slot">
                                <el-image :src="defaultImg" class="center_image"></el-image>
                            </div>
                        </el-image> -->
                        <div class="img_bg" :style="`background:url(${scope.row.bg_image}) no-repeat;background-size: 100% 100%;`">
                            <div class="title">{{scope.row.title}}</div>
                            <div class="center_txt">
                                尊享余额<span class="text">{{scope.row.charge_balance_amount}}</span>元
                            </div>
                            <div class="bom_text">{{scope.row.sub_title}}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="售价" prop="price" width="200" align="center"></el-table-column>
                <el-table-column label="描述" prop="desc" width="auto" align="center"></el-table-column>
                <el-table-column label="有效期" prop="time" width="150" align="center"></el-table-column>
                <el-table-column label="活动售价(￥)" prop="price" width="150" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary">售价:{{ scope.row.price }}</el-tag>
                        <el-tag type="primary">活动价范围（{{ scope.row.price_min }} - {{ scope.row.price_max }} )</el-tag>
                        <el-tag type="primary">最高售价:{{ scope.row.price_max }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="活动库存" prop="stock" align="center" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.user_grade_shop_distributor===null">
                            <el-tag type="info">未发售</el-tag>
                        </div>
                        <div v-else>
                            {{scope.row.user_grade_shop_distributor.stock}}
                        </div>

                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.user_grade_shop_distributor===null">
                            <el-tag type="info">未发售</el-tag>
                        </div>
                        <div v-else>
                            <el-tag type="primary" v-if="scope.row.state === 1">下线</el-tag>
                            <el-tag type="success" v-if="scope.row.state === 2">上线</el-tag>
                        </div>

                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="120" fixed="right">
                    <template slot-scope="scope">
                        <div v-if="scope.row.user_grade_shop_distributor===null">
                            <el-button type="text" icon="el-icon-sell" @click="add(scope.$index, scope.row)">发售</el-button>
                        </div>
                        <div v-else>
                            <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                            <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                            </el-button>
                            <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                                上线</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 编辑客户等级 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="900px" v-if="addVisible" class="dialog_box">
            <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="left" label-suffix=":" label-width="180px" size="large" element-loading-text="上传中">

                <el-form-item label="售价" prop="price">
                    <el-input v-model="form.price" placeholder="请输入价格"></el-input>
                </el-form-item>
                <el-form-item label="库存" prop="stock">
                    <el-input v-model="form.stock" placeholder="请输入库存"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>

        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb'
import Pagination from '@/components/Pagination'
import { request } from '@/common/request'
import { deal } from '@/common/main'
import loadEvents from '@/utils/loading'
import Picture from '@/components/Picture';
import { IMAGE_UPLOAD_PRO_URL } from "../../common/const";

let loads = new loadEvents();
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination,
        Picture
    },
    data() {
        return {
            sysConfigs: {},
            IMAGE_UPLOAD_PRO_URL: IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            disabled: false,
            defaultImg: require('../../assets/img/zhan.jpeg'),
            menuList: ['客户', '升级卡券'],
            loading: false,
            confirmVisible: false,
            confirmContent: '',
            header: {},
            queryParams: {
                page: 1,
                pagesize: 10,
                biz_type:"PreCharge"
            },
            pageInfo: {},
            addVisible: false,
            addVisibles: false,
            title: "发售卡券",
            rules: {
                price: [{
                    required: true,
                    message: '价格不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
                stock: [{
                    required: true,
                    message: '库存数量不能为空',
                    trigger: 'blur'
                }],

            },
            currentGradeShop: null,
            mode: 'add',
            form: {
                user_grade_shop_id: 0,
                state: 0,
                price: 0.00,
                stock: 0
            },
            gradeList: [],
            shop: 0,
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
        this.getGradeList()
    },
    methods: {
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function() {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function() {
                    show[0].style['display'] = 'none'
                })
            })
        },
        //获取等级列表
        getGradeList() {
            request.get("/account/grade/list", { page: 1, pagesize: -1, state: 2 }).then((ret) => {
                if (ret.code == 1) {
                    this.gradeList = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            })
        },
        //状态
        state(row, state) {
            if (state === 1) {
                this.confirmContent = '确定是否下线?';
                this.form = Object.assign({}, row.user_grade_shop_distributor);
                this.form.state = state;
                this.confirmVisible = true;
            } else {
                this.confirmContent = '确定是否上线?';
                this.form = Object.assign({}, row.user_grade_shop_distributor);
                this.form.state = state;
                this.confirmVisible = true;
            }
        },

        loadData() {
            this.loading = true;
            //升级配置列表
            request.get('/account/grade/goods/list', this.queryParams).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    console.log(22222, this.pageInfo)
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function(val) {
                        val.time = val.time + '天'
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        add(index, row) {
            this.currentGradeShop = row
            this.mode = 'add';
            this.disabled = false
            this.form = {
                user_grade_shop_id: row.id,
                state: 2,
            }
            this.addVisible = true
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.disabled = true
            this.disabled = false
            this.title = '编辑';
            //处理时间
            this.form = Object.assign({}, row.user_grade_shop_distributor);
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        delete(id, index) {

        },
        changeCount(num) {
            this.form.num = num
        },

        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        let time = parseInt(this.form.time)
                        let params = this.form = Object.assign({}, this.form);
                        var url = this.mode === 'add' ? '/account/grade/goods/distributor/add' : '/account/grade/goods/distributor/edit';
                        request.post(url, params).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.addVisibles = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/account/grade/goods/distributor/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        tableRowClassName({ row, rowIndex }) {
            if (row.user_grade_shop_distributor === null) {
                return 'disabled-row';
            }
            return '';
        }
    }
}

</script>

<style lang="scss" scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
.dialog_box .el-button {
    margin-left: 20px;
}
.disabled-row {
    background-color: #007042;
}

.img_bg {
    width: 314px;
    height: 175px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;

    .title {
        font-size: 25px;
        color: #ffffff;
    }
    .center_txt {
        font-size: 24px;
        color: #ffffff;
        .text {
            font-size: 36px;
            font-weight: bold;
            color: #ffd13a;
            padding: 0 2px;
        }
    }
    .bom_text {
        font-size: 18px;
        color: #ffffff;
    }
}
</style>
